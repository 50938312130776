@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Rubik Regular";
        src: url('./fonts/Rubik-Regular.ttf');
    }
    @font-face {
        font-family: "Rubik SemiBold";
        src: url('./fonts/Rubik-SemiBold.ttf');
    }
    @font-face {
        font-family: "Surfbars Italic";
        src: url('./fonts/Surfbars-Italic.ttf');
    }
}